<script setup>
import { __ } from "../order/composables/lang";
import MarkerStripeHover from "./MarkerStripeHover.vue";
import SvgUse from "./SvgUse.vue";
import { ref, watch } from "vue";

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
    required: true,
  },
});

const navigateTo = (item) => {
  if (!item.url) {
    return;
  }
  window.location.href = item.url;
};

const loaded = ref([]);

const initializeLoaded = () => {
  loaded.value = Array(props.items.length).fill(false);
};

watch(() => props.items, initializeLoaded, { deep: true, immediate: true });
</script>
<template>
  <div class="#grid #grid-cols-4 #gap-[30px] #text-inkdropdark #bg-inkdroplight">
    <div
      v-for="(item, i) in items"
      :key="i"
      class="#group #cursor-pointer #relative #w-full #aspect-square #rounded-lg #overflow-hidden"
      :class="{
        '#p-[25px]': item.type === 'custom',
        '#bg-[#d5d5d5]': !item.image,
        'form-loader-animation': !loaded[i],
      }"
      @click="navigateTo(item)"
    >
      <p
        v-if="item.new_product"
        class="imperfect-rectangle #text-white #text-sm #font-medium #absolute #top-12 #right-0 #bg-vividmagenta #px-4 #py-2 #z-[1]"
      >
        {{ __("Nieuw") }}
      </p>
      <img
        v-if="item.type === 'custom'"
        src="/img/dotted_square.svg"
        class="#absolute #left-0 #top-0 #w-full #h-full"
      />
      <img
        :src="item.image"
        :alt="item.title"
        class="#w-full #h-full #object-cover group-hover:#scale-105 #ease-out #duration-200 #transition-transform #rounded-lg #overflow-hidden"
        @load="loaded[i] = true"
      />
      <svg-use
        v-if="item.type === 'product' && item.description"
        id="circle-info"
        type="solid"
        class="#w-[1em] #h-[1em] #absolute #top-0 #right-0 #fill-[#707070] #pb-5 #pl-5 #pt-3 #pr-3 #box-content #hidden group-hover:#block #peer/info #z-10 hover:#fill-white"
        @click.stop
      ></svg-use>
      <div
        v-if="item.description"
        class="#bg-inkdropdark/80 #absolute #w-full #h-full #left-0 #top-0 #p-[10%] #opacity-0 peer-hover/info:#opacity-100 #transition-all #duration-300 #ease-out peer-hover/info:*:#left-0 #z-[1]"
      >
        <p
          class="#text-white #text-xs #transform-3d #-left-4 #relative #transition-all #duration-300 #max-h-[70%] #line-clamp-4 min-[1080px]:#line-clamp-5 xl:#line-clamp-6 #overflow-hidden"
        >
          {{ item.description }}
        </p>
      </div>
      <div
        class="#py-2 #px-4 #absolute #left-0 #bottom-6 #z-10 #max-w-full"
        :class="
          item.type === 'custom' ? '#bg-inkdropdark #text-white' : '#bg-white #text-inkdropdark'
        "
      >
        <div class="#font-bold #relative">
          <MarkerStripeHover class="#text-nowrap #font-barlowBold"
            >{{ item.title }}
          </MarkerStripeHover>
        </div>
      </div>
      <div
        v-if="item.type === 'product'"
        class="#absolute #bg-gradient-to-r #from-inkdropdark #top-0 #left-0 #w-full #h-full #opacity-0 #transition-opacity #duration-200 group-hover:#opacity-10"
      ></div>
    </div>
    <template v-if="items.length < 8">
      <div v-for="i in 8 - items.length" :key="i" class="#w-full #aspect-square"></div>
    </template>
  </div>
</template>
