<script setup></script>
<template>
  <div>
    <p class="#z-[1] #relative #text-ellipsis #overflow-hidden"><slot></slot></p>
    <svg
      id="Laag_2"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      data-name="Laag 2"
      viewBox="0 0 547.951 166.222"
      class="#w-full #absolute #h-[29%] #bottom-0 #fill-none #z-0"
    >
      <defs>
        <clipPath id="clippath">
          <path
            preserveAspectRatio="none"
            class="cls-2"
            d="M105.073,136.66c-13.812-11.251-90.307,9.643-98.042,6.128C1.682,138.92-.578,60.491,2.31,48.588c.954-4.169,2.888-5.148,7.383-3.541s45.329-4.169,49.825-4.822c22.828-3.214,44.676-6.429,44.676-6.429,1.281,2.26,4.169-6.103,5.148-3.214,1.281,3.214,37.62.326,74.887-2.562,37.268-2.888,76.193-6.103,82.296-6.429,7.383-.326,1.607,4.495,10.296,2.562,18.333-4.169,59.142-.326,72.979-4.169,11.251-3.214,13.511-3.214,24.435-.326,10.924,2.888,69.764.954,76.495-4.169,1.607-1.281,2.888-.954,2.888.954l6.429.653c1.281,0,34.706,3.214,35.033,4.495.326,1.607,1.607,2.562,2.562,1.607,2.26-1.934,46.284-5.148,46.937,0,1.281,14.465-7.383,75.867-12.858,90.332-5.776,15.419-32.798-4.169-55.601,4.169-41.462-1.607-50.477-2.26-104.144-3.214l-22.175,5.776c-2.562-5.148-12.205,7.71-18.333-1.607-2.562-3.867-20.241-4.169-24.109-.326-1.607,1.607-25.389.326-49.498.653-24.435.326-49.498,1.607-53.692,7.082-18.634-8.991-89.051,8.689-98.996,10.598h-.1,0Z"
          />
        </clipPath>
      </defs>
      <g
        class="cls-3 [stroke-dasharray:650] [stroke-dashoffset:650] #transition-all group-hover:![stroke-dashoffset:0]"
        clip-path="url(#clippath)"
      >
        <path
          preserveAspectRatio="none"
          class="#stroke-brightcyan #stroke-[130px]"
          d="M-3.294,102.286s285.773-57.189,544.362-29.544"
        />
      </g>
    </svg>
  </div>
</template>
