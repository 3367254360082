<template>
  <div class="tailwind">
    <div
      id="intercom_button"
      ref="intercom_button"
      class="#fixed #bottom-0 #w-full #transition-all #flex #flex-col #items-end #pointer-events-none #z-10 min-[451px]:#z-30"
      :style="{ bottom: spaceFromBottom + 'px' }"
    >
      <div
        v-if="error"
        class="imperfect-rectangle #relative #bottom-full #mb-4 #mx-[20px] #flex #gap-x-4 #justify-between #items-center #px-6 #py-4 #min-h-[70px] !#bg-[#d9534f] #text-white #font-semibold #text-sm #max-w-[350px]"
      >
        {{ error }}
      </div>
      <div
        v-outside-click="close"
        class="#pointer-events-auto #bg-white #text-inkdropdark #rounded-full #ml-auto #w-28 #h-28 #relative #right-8 min-[768px]:!#right-8 #bottom-0 min-[768px]:!#bottom-0 #flex #items-center #justify-center #shadow-intercom #transition-all #z-10 min-[451px]:#z-[9999999999] #cursor-pointer hover:#scale-110"
        :class="{ 'showIntercomButton #scale-0': animateIcon, '#scale-0': !showApp }"
        @click="toggleIntercomApp"
      >
        <div id="intercom_regular" class="#flex #justify-center #items-center">
          <img
            src="/img/intercom_icon.svg"
            alt=""
            width="44px"
            height="38px"
            class="intercom-icon #transition-all"
            :class="{ '#scale-0': loadingIntercom || showIntercom || error }"
          />
          <svg-use
            id="angle-down"
            type="solid"
            class="#absolute #w-[24px] #h-[24px] #transition-all #-rotate-180"
            :class="{ '#scale-0 #rotate-180 #opacity-0': !showIntercom }"
          />
          <svg-use
            id="triangle-exclamation"
            type="solid"
            class="#h-14 #w-14 #fill-red-500 #absolute #transition-all"
            :class="error ? '#scale-100' : '#scale-0'"
          />
        </div>
        <div
          id="intercom_loader"
          class="#transition-all #flex #absolute #justify-center #gap-x-1"
          :class="loadingIntercom ? '#scale-100' : '#scale-0'"
        >
          <div
            id="loader-1"
            class="#bg-vividmagenta #relative #border-inkdropdark #border-4 #w-6 #h-6 #rounded-full"
          ></div>
          <div
            id="loader-2"
            class="#bg-brightcyan #relative #border-inkdropdark #border-4 #w-6 #h-6 #rounded-full"
          ></div>
          <div
            id="loader-3"
            class="#bg-vividmagenta #relative #border-inkdropdark #border-4 #w-6 #h-6 #rounded-full"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mobile } from "../mixins";
import intercom from "../intercom";
import SvgUse from "../../vue3/components/SvgUse.vue";
import outsideClick from "../directives/outsideClick.js";

export default {
  directives: {
    outsideClick,
  },
  components: {
    SvgUse,
  },
  mixins: [mobile],
  data() {
    return {
      animateIcon: false,
      loadingIntercom: false,
      showIntercom: false,
      showApp: false,
      spaceFromBottom: 20,
      error: null,
    };
  },

  computed: {
    ...mapGetters(["route"]),
  },
  watch: {
    screenWidth(newValue) {
      if (newValue > 519 && !this.showApp) {
        this.animateIcon = true;
        setTimeout(() => {
          this.animateIcon = false;
          this.showApp = true;
        }, 1300);
      } else if (this.collidingWithLanding()) {
        this.showApp = false;
      }
    },
  },
  created() {
    this.emitter.on("trigger-intercom", (method) => {
      this.triggerMethod(method);
    });

    this.emitter.on("hide-intercom-app", () => {
      this.showApp = false;
    });

    this.emitter.on("show-intercom-app", () => {
      this.showApp = true;
    });

    this.emitter.on("set-intercom-location", (bottom) => {
      this.spaceFromBottom = bottom;
    });
  },
  mounted() {
    var url = new URL(window.location.href);
    if (this.collidingWithLanding() && !url.searchParams.has("notification_link_uid")) {
      return;
    }
    this.animateIcon = true;
    this.showApp = true;
    setTimeout(() => {
      this.animateIcon = false;
      if (url.searchParams.has("notification_link_uid")) {
        this.triggerMethod("showMessages");
      }
    }, 1300);
  },
  beforeUnmount() {
    this.emitter.off("trigger-intercom");
    this.emitter.off("hide-intercom-app");
    this.emitter.off("show-intercom-app");
    this.emitter.off("set-intercom-location");
  },
  methods: {
    initializeIntercom() {
      return new Promise((resolve, reject) => {
        fetch(this.route("intercom"))
          .then((response) => response.json())
          .then(async (data) => {
            await intercom(data);

            window.Intercom("onShow", this.show);
            window.Intercom("onHide", this.hide);

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async triggerMethod(method) {
      if (this.error) {
        this.error = null;
      }
      try {
        if (!window.Intercom) {
          this.loadingIntercom = true;
          await this.initializeIntercom();
        }
        window.Intercom(method);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 5000);
        this.loadingIntercom = false;
      }
    },
    toggleIntercomApp() {
      if (this.showIntercom) {
        this.triggerMethod("hide");
      } else {
        this.triggerMethod("show");
      }
    },
    show() {
      if (this.loadingIntercom) {
        this.loadingIntercom = false;
      }
      this.showIntercom = true;
    },
    hide() {
      this.showIntercom = false;
    },
    close() {
      if (this.showIntercom) {
        this.triggerMethod("hide");
      }
    },
    collidingWithLanding() {
      if (this.screenWidth > 519) {
        return false;
      }
      if (
        this.$refs.intercom_button.offsetTop >
        document.getElementById("landing-div").getBoundingClientRect().height
      ) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style>
.showIntercomButton {
  animation: showIntercom 300ms 1s cubic-bezier(0, 0, 0.07, 2.88) forwards;
}

.intercom-lightweight-app-messenger,
.intercom-messenger-frame {
  margin-bottom: 100px !important;
  max-height: 80vh !important;
}

#loader-1 {
  animation: loadIntercom 1300ms cubic-bezier(0.32, 0.06, 0.48, 0.96) infinite;
}
#loader-2 {
  animation: loadIntercom 1300ms -1150ms cubic-bezier(0.32, 0.06, 0.48, 0.96) infinite;
}
#loader-3 {
  animation: loadIntercom 1300ms -1000ms cubic-bezier(0.32, 0.06, 0.48, 0.96) infinite;
}

@media only screen and (max-width: 451px) {
  .intercom-lightweight-app-messenger,
  .intercom-messenger-frame {
    max-height: 100vh !important;
    margin-bottom: 0px !important;
  }
}

@keyframes showIntercom {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes showIntercom {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes loadIntercom {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
