<script setup>
import { __ } from "../order/composables/lang";
import SvgUse from "../components/SvgUse.vue";
import { ref, watch, onBeforeMount, useTemplateRef, nextTick, computed } from "vue";
import InputField from "../components/InputField.vue";
import debounce from "lodash.debounce";
import Button from "../components/Button.vue";
import { useMobile } from "../composables/mobile";
import MenuGrid from "../components/MenuGrid.vue";
import { useStore } from "vuex";
import vOutsideClick from "../directives/outsideClick";
import MarkerStripeHover from "../components/MarkerStripeHover.vue";
import LoaderSpinner from "../components/LoaderSpinner.vue";
import SvgDetect from "../components/SvgDetect.vue";

const { screenWidth } = useMobile();

const open = ref(false);
const searchterm = ref("");
const selectedCategory = ref(null);
const showCategory = ref(true);
const store = useStore();
const allCategories = ref([]);

onBeforeMount(() => {
  allCategories.value = window.product_menu;

  if (screenWidth.value < 1024) {
    selectedCategory.value = null;
  }
});

const categories = computed(() => {
  return allCategories.value.filter((category) => {
    return !category.popular;
  });
});

const popularProducts = computed(() => {
  return allCategories.value.filter((category) => {
    return category.popular;
  });
});

const changeCategory = function (i) {
  if (searchterm.value) {
    searchterm.value = null;
  }

  if (i === selectedCategory.value && screenWidth.value < 1024) {
    selectedCategory.value = null;
  } else {
    selectedCategory.value = i;
  }
};

const container = useTemplateRef("scrollbarcontainer");
const containerPadding = ref(30);

const debouncedCategory = debounce((i) => {
  if (searchterm.value) {
    return;
  }
  changeCategory(i);
}, 250);

const toggleOpen = function () {
  open.value = !open.value;
  window.emitter.emit("close-dropdown-menus");
  if (open.value) {
    nextTick(() => {
      containerPadding.value = 30 - (container.value.offsetWidth - container.value.clientWidth);
    });
  }

  if (!open.value) {
    selectedCategory.value = null;
  }
};

watch(screenWidth, (newValue, oldValue) => {
  if (newValue > 1023 && oldValue < 1024) {
    open.value = false;
    selectedCategory.value = null;
  }
  if (newValue < 1024 && oldValue > 1023) {
    selectedCategory.value = null;
    open.value = false;
  }
});

const searchResults = ref([]);
const searching = ref(false);
const controller = ref(null);

const search = async () => {
  try {
    controller.value = new AbortController();
    const response = await fetch(store.getters.route("product.menu.search"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ search_term: searchterm.value }),
      signal: controller.value.signal,
    });

    if (response.ok) {
      searchResults.value = await response.json();
    } else if (!response.ok) {
      await response.text();
    }

    searching.value = false;
    controller.value = null;
  } catch (error) {
    console.log(error);
  }
};

const handleSearchInput = () => {
  if (controller.value) {
    controller.value.abort();
  }
  if (!searchterm.value) {
    searchResults.value = [];
    return;
  }
  searching.value = true;
  search();
};

const anchorElement = useTemplateRef("anchorElement");

const handleOutsideClick = (e) => {
  if (open.value === false) {
    return;
  }

  if (screenWidth.value < 1024) {
    return;
  }

  const path = e.composedPath ? e.composedPath() : e.path;

  if (path.includes(anchorElement.value)) {
    return;
  }

  open.value = false;
  selectedCategory.value = null;
};

const goToUrl = (url) => {
  if (!url) {
    return;
  }
  window.location = url;
};
</script>
<template>
  <div class="tailwind">
    <div class="#flex #flex-col lg:#flex-row max-lg:#w-full">
      <a
        ref="anchorElement"
        class="#text-white #cursor-pointer #group max-lg:#text-left max-lg:#w-full #py-[10px] lg:#py-[31px] #px-[20px] #whitespace-nowrap hover:#bg-inkdropdarklight"
        :class="{ '!#text-inkdropdark !#bg-white': open }"
        @click="toggleOpen"
      >
        {{ __("Producten") }}
        <svg-use
          id="angle-down"
          type="solid"
          class="#inline #mt-[-2px] #ml-[2px] #w-[0.75em] #h-[0.75em] #fill-white"
          :class="{ '!#fill-inkdropdark #rotate-180': open }"
        />
      </a>
      <div
        v-if="open"
        v-outside-click="($event) => handleOutsideClick($event)"
        class="#hidden lg:#flex #w-full lg:#absolute #rounded-[6px] #overflow-hidden #p-0 #left-0 #top-full lg:#max-h-[calc(100vh_-_100px)] #bg-inkdroplight"
        :class="selectedCategory === null && !searchterm ? '#h-[613px]' : '#h-[573px]'"
      >
        <div class="#w-[256px] #max-h-full #bg-white #sticky #top-0 #overflow-auto">
          <div
            v-for="(category, i) in categories"
            :key="category.title"
            class="#relative #group #px-[20px] #py-[9px] #cursor-pointer"
            @click="changeCategory(i)"
            @mouseenter="debouncedCategory(i)"
            @mouseleave="debouncedCategory.cancel()"
          >
            <div class="#flex #justify-between #items-center">
              <div class="#flex #items-center #gap-x-6">
                <svg-use
                  :id="category.icon"
                  type="solid"
                  class="#inline #w-[1em] #h-[1em] #fill-inkdropdark #z-10"
                  :class="{ '!#fill-inkdropdark': open }"
                />
                <p class="#relative #z-10 #w-max #border-b #border-dotted #border-transparent">
                  {{ category.title }}
                </p>
              </div>
            </div>
            <div
              class="#absolute #bg-inkdroplight #w-full #h-full #right-full #top-0 #transition-all #ease-in-out #duration-[250ms]"
              :class="{ 'group-hover:#right-0': !searchterm }"
            ></div>
            <div
              v-if="selectedCategory === i && showCategory && !searchterm"
              class="#absolute #bg-[#d5d5d5] #w-full #h-full #left-0 #top-0"
            ></div>
          </div>
        </div>
        <div
          ref="scrollbarcontainer"
          class="#flex-1 #bg-inkdroplight #h-full #overflow-y-auto"
          style="scrollbar-gutter: stable"
        >
          <div class="#flex-1 #bg-inkdroplight #h-max #min-h-full">
            <div
              class="#pb-8 #pt-[30px] #pl-[30px] #sticky #top-0 #z-20 #bg-inkdroplight"
              :style="`padding-right: ${containerPadding}px !important`"
            >
              <div class="#flex #mb-2 #gap-x-[15px]">
                <InputField
                  v-model="searchterm"
                  :label="__('Zoeken op alle producten')"
                  class="#font-normal #flex-1"
                  @input="handleSearchInput"
                >
                  <div
                    v-if="searching && searchterm"
                    class="#absolute #flex #w-full #h-full #items-center #justify-end #px-4"
                  >
                    <LoaderSpinner class="#w-max"></LoaderSpinner>
                  </div>
                </InputField>
                <Button
                  v-if="!searchterm"
                  btn-class="grey"
                  @click="goToUrl(categories[selectedCategory].url)"
                >
                  {{ __("Bekijk alles") }}
                  <svg-use
                    id="angle-right"
                    type="solid"
                    class="#inline-block #w-[0.75em] #h-[1em] #fill-inkdropdark"
                  />
                </Button>
              </div>
              <p v-if="searchterm" class="#text-[#707070] #text-xs">
                {{ searchResults.length }} {{ __("resultaten voor") }} "{{ searchterm }}"
              </p>
            </div>
            <div
              class="#pl-[30px] #pb-[30px]"
              :style="`padding-right: ${containerPadding}px !important`"
            >
              <template v-if="selectedCategory === null && !searchterm">
                <p class="#text-xl #font-barlowBold #mb-8">
                  <SvgDetect
                    id="streep"
                    class="#fill-brightcyan #absolute #w-full #h-[29%] #left-0 #bottom-0"
                  >
                    {{ __("Onze [populairste] producten") }}
                  </SvgDetect>
                </p>
                <MenuGrid :items="popularProducts[0]?.products"></MenuGrid>
              </template>
              <template v-for="(productcategories, index) in categories" :key="index">
                <div v-show="index === selectedCategory && !searchterm">
                  <MenuGrid :items="productcategories?.products"></MenuGrid>
                </div>
              </template>
              <template v-if="searchResults.length > 0 && searchterm">
                <MenuGrid :items="searchResults"></MenuGrid>
              </template>
              <template v-if="searchResults.length === 0 && !searching && searchterm">
                <div class="#p-[30px] #pt-[60px] #text-center #text-[#707070] #text-sm #space-y-4">
                  <svg-use
                    id="face-sad-tear"
                    type="solid"
                    class="#inline-block #w-[5em] #h-[5em] #fill-[#d5d5d5] #mb-8"
                  />
                  <p>{{ __("We konden helaas niks vinden voor ") }} "{{ searchterm }}"</p>
                  <p>
                    {{ __("Toch op zoek naar dit product?") }}
                    <span
                      class="#cursor-pointer #border-b #border-dotted #border-current hover:#text-vividmagenta"
                      @click="emitter.emit('trigger-intercom', 'showNewMessage')"
                      >{{ __("Neem contact op") }}</span
                    >
                    {{ __("met onze klantenservice") }}
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div v-if="open" class="#block lg:#hidden">
        <div
          v-for="(category, i) in categories"
          :key="category.title"
          class="#text-inkdropdark #border-t #border-solid #border-[#d5d5d5] #cursor-pointer hover:#bg-[#d5d5d5]"
          :class="selectedCategory === i ? '#bg-[#d5d5d5]' : '#bg-inkdroplight'"
        >
          <div class="#flex #items-center #gap-x-2 #px-[20px]" @click="changeCategory(i)">
            <div class="#w-full #flex #items-center #justify-between">
              <div class="#flex #justify-start #items-center #gap-x-4">
                <svg-use
                  :id="category.icon"
                  type="solid"
                  class="#w-[0.85em] #h-[0.85em] #fill-inkdropdark #z-10"
                  :class="{ '!#fill-inkdropdark': open }"
                />
                <p class="#py-4">
                  {{ category.title }}
                </p>
              </div>
              <svg-use
                id="angle-down"
                type="solid"
                class="#inline #mt-[-2px] #ml-[2px] #w-[0.75em] #h-[0.75em] #fill-[#707070]"
                :class="{ '#rotate-180': selectedCategory === i }"
              />
            </div>
          </div>
          <div v-show="selectedCategory === i" class="#bg-inkdroplight #p-[20px]">
            <div class="#grid #grid-cols-1 sm:#grid-cols-2 #gap-6">
              <div
                v-for="product in category?.products"
                :key="product.title"
                class="#group #relative #overflow-hidden #flex #items-center #bg-white #rounded-[6px] #shadow-modal"
                @click="goToUrl(product.url)"
              >
                <img
                  class="#w-[75px] #h-[75px] #object-cover"
                  :src="product.image"
                  :alt="product.title"
                />
                <div class="#py-[15px] #px-[15px] #relative">
                  <MarkerStripeHover
                    class="#relative #text-base #font-bold #break-words #font-barlowBold max-[400px]:#text-sm"
                  >
                    {{ product.title }}
                  </MarkerStripeHover>
                </div>
                <p
                  v-if="product.new_product"
                  class="imperfect-rectangle-mirrored #absolute #top-0 #right-0 #text-white #text-xs #w-max #font-medium #bg-vividmagenta #px-4 #py-2 #z-[1]"
                >
                  {{ __("Nieuw") }}
                </p>
              </div>
            </div>
            <div class="#flex #justify-center sm:#justify-end">
              <Button
                btn-class="grey"
                class="#mt-8"
                @click="goToUrl(categories[selectedCategory].url)"
              >
                {{ __("Bekijk alles") }}
                <svg-use
                  id="angle-right"
                  type="solid"
                  class="#inline #w-[1em] #h-[1em] #fill-inkdropdark"
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="open"
        class="#bg-inkdropdark/80 #left-0 #top-0 #w-screen #h-screen #fixed #z-[-1]"
      ></div>
    </div>
  </div>
</template>
